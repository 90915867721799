






























































import { Component, Vue, Watch } from "vue-property-decorator";

import AbTestSegmentStatistic from "@/ab-tests/components/AbTestSegmentStatistic.vue";
import ExternalFinishConfirmDialog from "@/ab-tests/components/ExternalFinishConfirmDialog.vue";
import ExternalTestCurrentState from "@/ab-tests/components/ExternalTestCurrentState.vue";
import { AbTestResultModel } from "@/ab-tests/models/AbTestConfigurationModel";
import { ConfigsStatus } from "@/ab-tests/models/ConfigsStatusModel";
import { ExternalTestModel } from "@/ab-tests/models/ExternalTest";
import { AppSection } from "@/shared/models";

@Component({
  components: {
    ExternalTestCurrentState,
    AbTestSegmentStatistic,
    ExternalFinishConfirmDialog,
  },
})
export default class ExternalTestView extends Vue {
  readonly AppSection = AppSection;
  isTestResultLoading = false;
  abTestResult: AbTestResultModel | null = null;
  isConfirmationDialogVisible = false;

  get test(): ExternalTestModel {
    return this.$store.state.externalTestStore.test;
  }

  get configId(): number {
    return +this.$route.params.configId;
  }

  get isTestLoading(): boolean {
    return this.$store.state.externalTestStore.isTestLoading;
  }

  get applicationId(): string {
    return this.$route.params.id;
  }

  @Watch("applicationId", { immediate: true })
  watchApplicationId() {
    this.abTestResult = null;

    this.$store
      .dispatch("getExternalTest", this.configId)
      .then(() => {
        if (this.test.status !== ConfigsStatus.STATISTICS_RECALCULATED) {
          return;
        }

        this.isTestResultLoading = true;
        this.$store
          .dispatch("getAbTestResult", [this.configId])
          .then((result: Array<AbTestResultModel>) => {
            this.abTestResult = AbTestResultModel.of(result[0]);
          })
          .finally(() => {
            this.isTestResultLoading = false;
          });
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.$router.push({ name: AppSection.EXTERNAL_TESTS });
        }
      });
  }

  showConfirmationDialog() {
    this.isConfirmationDialogVisible = true;
  }

  async finishTest(activeTill: string) {
    await this.$store.dispatch("finishExternalTest", {
      id: this.test.id,
      payload: { activeTill },
    });
    this.isConfirmationDialogVisible = false;
    this.$store.dispatch("getExternalTest", this.configId);
  }
}
