







































































import { Component, Vue, Prop } from "vue-property-decorator";

import { ConfigsStatus } from "@/ab-tests/models/ConfigsStatusModel";
import { ExternalTestModel } from "@/ab-tests/models/ExternalTest";

@Component
export default class ExternalTestCurrentState extends Vue {
  @Prop() externalTest!: ExternalTestModel;

  readonly steps = [
    ConfigsStatus.INIT,
    ConfigsStatus.STARTED,
    ConfigsStatus.CONFIG_DISTRIBUTION_FINISHED,
    ConfigsStatus.STATISTICS_RECALCULATION,
    ConfigsStatus.STATISTICS_RECALCULATED,
  ];

  get currentStep(): number {
    return this.externalTest.status
      ? this.steps.findIndex((step) => step === this.externalTest.status) + 1
      : 0;
  }

  get isEditable(): boolean {
    return (
      this.externalTest.status === ConfigsStatus.STARTED &&
      this.externalTest.hasEditAccess
    );
  }
}
